import React from 'react'

import {
    Button, Card, CardActionArea, CardActions, CardContent, Grid, Link, Typography
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'

import { Affiliate } from '../../models/blogPost/blogPost'

/**
 * imgに関して、アフィリエイト規約上、アスペクト比維持が必要なので
 */
const useStyles = makeStyles({
  card: {
    margin: 3
  },
  title: {
    fontSize: 16,
    color: grey[600]
  },
  img: {
    textAlign: 'center',
    '& a img': {
      maxWidth: '100%'
    }
  },
  buttonArea: {
    textAlign: 'center'
  },
  amazonFont: {
    fontColor: grey[50]
  },
  empty: {
  }
})

type Props = {
  affiliates?: Affiliate[]
}

const AffiliateLink = ({ affiliates }: Props) => {
  const style = useStyles()
  return (
    <>
      {affiliates && (<h3 className={style.empty}>〜商品紹介〜</h3>)}
      <Grid container className={style.empty} >
        {affiliates?.map((affiliate: Affiliate) => (
          <Grid item xs={6} sm={6} md={4}>
            <Card className={style.card}>
              <CardActionArea>
                <div className={style.img} dangerouslySetInnerHTML={{ __html: affiliate.amazonImage.amazonImage }} />
              </CardActionArea>
              <CardContent>
                <Typography className={style.title}>
                  <Link href={affiliate.amazonLink} color="inherit">
                    {affiliate.name}
                  </Link>
                </Typography>
              </CardContent>
              <CardActions className={style.buttonArea}>
                {affiliate.rakutenLink && (
                  <Button variant="contained" fullWidth={true} size="medium" color="secondary" href={affiliate.rakutenLink.rakutenLink} >楽天</Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid >
    </>
  )
}

export default AffiliateLink
