import 'github-markdown-css'
import 'prismjs/themes/prism-twilight.css'

import React from 'react'
import styled from 'styled-components'

// NOTE: 独自color
const MarkdownArea = styled.div`
  padding:20px 0; 

  word-break: break-word;
  &:not(:first-child) {
    margin: 0px;
  }
  & > h2 {
    margin-top: 20px;
    font-size: 28px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing:2px;
    width: 100%;
    border-left: solid 12px #29272f;
    padding-left: 15px;

  }
  & > h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.6;
    letter-spacing:2px;
    border-left: solid 8px #69676f;
    border-bottom: solid 1px #eaecef;
    padding-left: 10px;
  }
  & > h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.8;
    letter-spacing: 2px;
    border-left: solid 4px #a9a7af;
    border-bottom: solid 1px #eaecef;
    padding-left: 10px;
  }
  & > p {
    line-height: 1.6;
    white-space: pre-line;
    letter-spacing:1px;
    margin-left: 10px;
  }
  & a {
    color: #00C5B2;
    text-decoration: underline;
  }
  & > ul > li {
    list-style: disc;
    margin-left: 20px;
  }
  & > ol > li {
    list-style: decimal;
    margin-left: 20px;
  }
  & > ul > li:not(:first-child),
  & > ol > li:not(:first-child) {
    margin-top: 5px;
  }
  & > h2:not(:first-child) {
    margin-top: 0px;
  }
  & > h3:not(:first-child),
  & > p:not(:first-child),
  & > ul:not(:first-child),
  & > ol:not(:first-child),
  & > pre:not(:first-child) {
    margin-top: 0px;
  }
  @media screen and (max-width: 768px) {
    &:not(:first-child) {
      margin-top: 0px;
    }
    & > h2 {
      margin-top: 10px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing:1px;
    }
    & > h3 {
      font-size: 20px;
      line-height: 1.6;
      letter-spacing:0.5px;
      padding-left: 10px;
    }
    & > h4 {
      font-size: 18px;
      line-height: 1.6;
      letter-spacing:0.5px;
      padding-left: 5px;
    }
    & > table {
      display: block;
      overflow-x: scroll;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
    }
    & > p {
      font-size: 18px;
      line-height: 1.6;
      letter-spacing:0px;
    }
    & > ul > li,
    & > ol > li {
      font-size: 18px;
      line-height: 1.5;
    }
    & > ul > li:not(:first-child),
    & > ol > li:not(:first-child) {
      margin-top: 5px;
    }
    & > h2:not(:first-child) {
      margin-top: 30px;
    }
    & > h3:not(:first-child),
    & > p:not(:first-child),
    & > ul:not(:first-child),
    & > ol:not(:first-child),
    & > pre:not(:first-child) {
      margin-top: 0px;
    }
  }
`

type Props = {
  html: string
}

const Markdown = ({ html }: Props) => {

  return (
    <div className='markdown-body'>
      <MarkdownArea dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default Markdown
