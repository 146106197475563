export interface ArticleGoogleStructuredConfig {
  structured?: ArticleGoogleStructured
}

export class ArticleGoogleStructured {
  headline: string
  dataPublished: string // 2020-03-16 の形
  dateModified: string // 2020-03-16 の形
  image: string // 横幅1200px以上
  description: string
  articleSection: string
  url: string

  constructor(
    article: IArticleGoogleStructured
  ) {
    this.headline = article.headline
    this.dataPublished = article.dataPublished
    this.dateModified = article.dateModified
    this.image = article.image
    this.description = article.description
    this.articleSection = article.articleSection
    this.url = article.url
  }

  ldJson = () => {
    const obj = {
      "@context": "http://schema.org",
      "@type": "Article",
      "name": this.headline,
      "headline": this.headline,
      "author": {
        "@type": "Person",
        "name": "Yuto Hongo"
      },
      "image": {
        "@type": "ImageObject",
        "url": `https:${this.image}`, // contentfulの仕様, 横幅1200px以上
        "height": 630,
        "width": 1200
      },
      "description": this.description,
      "articleSection": this.articleSection,
      "url": this.url,
      "mainEntityOfPage": this.url,
      "publisher": {
        "@type": "Organization",
        "name": "Yuto Hongo Portfolio",
        "logo": {
          "@type": "ImageObject",
          "url": "https://hongo.dev/amp_logo.png",
          "width": 480,
          "height": 60
        }
      },
      "datePublished": this.dataPublished, // 2020-03-16 の形
      "dateModified": this.dateModified // 2020-03-16 の形
    }
    return JSON.stringify(obj)
  }
}

export interface IArticleGoogleStructured {
  headline: string
  dataPublished: string // 2020-03-16 の形
  dateModified: string // 2020-03-16 の形
  image: string // 横幅1200px以上
  description: string
  articleSection: string
  url: string
}