import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import React, { Component } from 'react'
import AdSense from 'react-adsense'

import { Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'

import Layout from '../../components/layout'
import AffiliateLink from '../../components/molecules/affiliateLink'
import Categories from '../../components/molecules/categories'
import ShareButtons from '../../components/molecules/share'
import YouTubeEmbed from '../../components/molecules/youTubeEmbed'
//@ts-ignore
import YouTubeSubscribe from '../../components/molecules/youTubeSubscribe'
import Markdown from '../../components/organisms/markdown'
import BlogCategory from '../../models/blogCategory/blogCategory'
import { BlogPost } from '../../models/blogPost/blogPost'
import {
  ArticleGoogleStructured, ArticleGoogleStructuredConfig
} from '../../models/seoConfig/ArticleGoogleStructure'
import SEOConfig from '../../models/seoConfig/seoConfig'
import PageType from '../../models/siteConfig/pageType'
import SiteConfig from '../../models/siteConfig/siteConfig'

const styles = {
  article: {
    margin: 3
  },
  articleHead: {
    textAlign: 'center'
  },
  title: {
    margin: 8,
    textAlign: 'left',
    fontSize: 18
  },
  heroImage: {
    margin: 15,
    borderRadius: '15px 15px 15px 15px',
    filter: 'drop-shadow(0px 0px 5px rgba(0,0,0,1))'
  },
  categoryArea: {
    padding: 10,
  },
  chip: {
    margin: '.3em'
  },
  right: {
    textAlign: 'right',
    fontSize: 14,
    color: grey[400]
  }
}

class BlogPostTemplate extends Component {
  render() {
    const blogCategories: BlogCategory[] = get(this, 'props.data.allContentfulBlogPostCategory.nodes')
    const blogPost: BlogPost = get(this, 'props.data.contentfulBlogPost')
    const siteConfig: SiteConfig = get(this, 'props.data.site.siteMetadata.siteConfig')
    const classes = get(this, 'props.classes')
    // twitter card 用
    const twitterVideoCheck = () => {
      if (blogPost.vlog) {
        return {
          vlogflg: true,
          vlogiframe: `https://www.youtube.com/embed/${blogPost.slug}?autoplay=1`
        }
      } else {
        return {
          vlogflg: false,
          vlogiframe: null
        }
      }
    }

    const twitterVideo = twitterVideoCheck()

    const seoConfig = new SEOConfig(
      PageType.Article,
      blogPost.title,
      blogPost.description.description,
      blogPost.slug,
      siteConfig,
      blogPost.heroImage.fixed.src,
      null,
      twitterVideo.vlogflg,
      twitterVideo.vlogiframe
    )
    const articleStructured: ArticleGoogleStructuredConfig = {
      structured: new ArticleGoogleStructured({
        headline: blogPost.title,
        dataPublished: blogPost.createdAt,
        dateModified: blogPost.updatedAt,
        image: blogPost.heroImage.fixed.src,
        description: blogPost.description.description,
        articleSection: blogPost.content.childMarkdownRemark.excerpt,
        url: seoConfig.ogpURL()
      })
    }
    return (
      <Layout seoConfig={seoConfig} blogCategories={blogCategories} articleStructured={articleStructured}>
        <article className={classes.article}>
          <div className={classes.articleHead}>
            <h1 className={classes.title}>{blogPost.title}</h1>
            {blogPost.vlog && <YouTubeEmbed slug={blogPost.slug} />}
            {!!!blogPost.vlog && <Img sizes={{ ...blogPost.heroImage.fluid, aspectRatio: 1.91 }} className={classes.heroImage} />}
            <Grid container className={classes.categoryArea}>
              <Categories categories={blogPost.categories} />
            </Grid>
            {blogPost.vlog && <YouTubeSubscribe />}
            <ShareButtons url={seoConfig.ogpURL()}></ShareButtons>
          </div>
          <AffiliateLink affiliates={blogPost.affiliates} />
          <Markdown html={blogPost.content.childMarkdownRemark.html} />
          <AffiliateLink affiliates={blogPost.affiliates} />
          <div className={classes.right}>投稿日：{blogPost.createdAt}</div>
          <div className={classes.right}>更新日：{blogPost.updatedAt}</div>
          <div className={classes.articleHead}>
            <ShareButtons url={seoConfig.ogpURL()}></ShareButtons>
          </div>
        </article>
        <div>
          <AdSense.Google
            style={{ display: 'block' }}
            client='ca-pub-5524131539322102'
            slot='4430167272'
            format='auto'
            responsive='true'
          />
        </div>
      </Layout >
    )
  }
}

export default withStyles(styles)(BlogPostTemplate)

export const blogPost = graphql`
query blogPostQuery($slug: String!) {
  site {
    siteMetadata {
      siteConfig {
        title
        description
        siteImageSrc
        baseURL
        author {
          name
          nameCall
          bio
          medias {
            name
            link
            viewBox
            paths {
              d
              fill
            }
          }
        }
      }
    }
  }
  allContentfulBlogPostCategory {
    totalCount
    nodes {
      name
      slug
    }
  }
  contentfulBlogPost(slug: {eq: $slug}) {
    title
    slug
    description{
      description
    }
    categories {
      name
      slug
    }
    heroImage{
      file{
        url
      }
      fixed(width:1200,height:630){
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(maxWidth:720, quality:30){
        ...GatsbyContentfulFluid_withWebp
      }
    }
    content {
      childMarkdownRemark {
        excerpt(pruneLength: 99999)
        html
      }
    }
    affiliates {
      amazonLink
      amazonImage {
        amazonImage
      }
      rakutenLink {
        rakutenLink
      }
      name
    }
    createdAt(formatString: "YYYY-MM-DD")
    updatedAt(formatString: "YYYY-MM-DD")
    vlog
  }
}
`
