import * as React from 'react'
import styled from 'styled-components'

const EmbedWrapper = styled.div`
  margin-top: 5px;
  position:relative;
  width:100%;
  padding-top:56.25%;
`

const Iframe = styled.iframe`
  position:absolute;
  top:0;
  right:0;
  width:100%;
  height:100%;
`

interface Props {
  slug: string
}

const YouTubeEmbed: React.FC<Props> = ({ slug }) => {

  const autoEmbedURL = `https://www.youtube.com/embed/${slug}?autoplay=1&mute=1`

  return (
    <EmbedWrapper>
      <Iframe src={autoEmbedURL} />
    </EmbedWrapper>
  )
}

export default YouTubeEmbed